import selectInvoice from './selectInvoice';
import unProcessed from './unProcessed';
import inProcess from './inProcess';
import processed from './processed';
import errorDetails from './errorDetails'
export default {
    name: 'recoveryDetails',
		components: {
			selectInvoice,
			unProcessed,
			inProcess,
			processed,
			errorDetails
		},
		data() {
			return {
				loader: false
			}
		}
}
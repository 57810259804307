import commonHelper from '@/app/utility/common.helper.utility';
import addEditTenantRecovery from './addEditTenantRecovery';
import recoveryDetails from './recoveryDetails'
export default {
	name: 'tenantSecurityDeposit',
	components: {
		addEditTenantRecovery,
		recoveryDetails
	},
	data() {
		return {
			showRecoveryDetailModal: false,
			unsubscribe: null,
			showAddRecoveryModal: false,
			loader: false,
			unitDisplay: null,
			perPage: commonHelper.perPageRecord,
			pageOptions: commonHelper.getPageOption(),
			totalRows: null,
			currentPage: 1,
			leaseUnitRowDetails: null,
			leaseUnitData: [
				{
					recovery_type: 'Property Tax Refund',
					batch_name: 'Test',
					project_name: 'First India Place CL',
					batch_date: '30/12/2021',
					area: 'Rented',
					Rate_per_sqft: 13.5,
					from_date: '01/12/2021',
					to_date: '31/12/2021',
					expense_amount: '100021.21222.33321',
					liability_account: '200244.552321.765434',
					tax_category: 'CN Leasing Other 18% CL',
					invoice_type: 'Standard Invoice',
					location: 'First India Place',
					SAC: null,
					status: 'New',
					detail: 'Details',
					created_by: 'S21212',
					creation_date: '05/12/2021',
				}
			],
			leaseUnitFields: [
				{
					key: 'tenant_lease_number'
				},
				{
					key: 'investor_number'
				},
				{
					key: 'vendor'
				},
				{
					key: 'vendor_site'
				},
				{
					key: 'currency'
				},
				{
					key: 'UOM'
				},
				{
					key: 'number'
				},
				{
					key: 'amount'
				},
				{
					key: 'sd_invoice_number'
				},
				{
					key: 'sd_account'
				},
				{
					key: 'status'
				},
				{
					key: 'sd_balance'
				},
				{
					key: 'receipt_details'
				},

			]
		}
	},
	mounted() {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'shared/setActionName') {
				const actionName = state.shared.actionName;
				if (actionName === 'add') {
					this.showAddRecoveryModal = true;
				}
			}
		});
	},
	methods: {
		rowSelected() {
			this.showRecoveryDetailModal = true;
		},
		showHideRecoveryModal(flag) {
			this.showAddRecoveryModal = flag;
		},
		showHideRecoveryDetailsModal(flag) {
			this.showRecoveryDetailModal = flag;
		}
	}
}
import selectInvoice from '../recoveryDetails/selectInvoice';
import unProcessed from '../recoveryDetails/unProcessed';
import inProcess from '../recoveryDetails/inProcess';
import processed from '../recoveryDetails/processed';
import errorDetails from '../recoveryDetails/errorDetails'
export default {
    name: 'addEditTenantRecovery',
		components: {
			selectInvoice,
			unProcessed,
			inProcess,
			processed,
			errorDetails
		},
		data() {
			return {
				loader: false,
				leaseUnitData: [],
				leaseUnitFields: [
					{
						key: ''
					}
				]
			}
		}
}